import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import styled from '@emotion/styled';
import clamp from 'lodash/clamp';
import moment from 'moment';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { useMouseHovered } from 'react-use';

import { size, theme, zIndex } from 'twig';

export const TimerProgressBar = ({
  currentMillis,
  totalMillis,
  onSelectTime,
}: {
  currentMillis: number;
  totalMillis: number;
  onSelectTime: (seconds: number) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lastTime = useRef<number>(0);
  const percentage = clamp(currentMillis / totalMillis, 0, 1);
  const timeChange = currentMillis - lastTime.current;

  useEffect(() => {
    lastTime.current = currentMillis;
  }, [currentMillis]);

  // This is to make the bar snappy. We normally smooth width changes
  // with a css transition. However, when there is a discontinuous time jump
  // this is probably because the user restarted the video, or jumped to a different
  // time. We don't want to smooth this change
  const didTimeJump = timeChange < 0 || timeChange > 600;
  const disableWidthAnimation = didTimeJump;

  const position = useMouseHovered(containerRef, { bound: false, whenHovered: true });
  const virtualReference = useMemo(
    () => ({
      getBoundingClientRect() {
        const barY = containerRef.current?.getBoundingClientRect().top || 0;
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return {
          top: barY - 50,
          left: position.docX,
          bottom: barY - 50,
          right: position.docX,
          width: 0,
          height: 0,
        } as DOMRect;
      },
    }),
    [position.docX]
  );
  const portalDiv = document.getElementById('root');
  const getTargetTime = useCallback(
    (xPosition: number) => {
      if (!containerRef.current) return null;
      const bounds = containerRef.current.getBoundingClientRect();
      const positionPercent = clamp((xPosition - bounds.x) / bounds.width, 0, 1);
      return totalMillis * positionPercent;
    },
    [totalMillis]
  );
  const targetTimeLabel = useMemo(
    () => moment.utc(getTargetTime(position.docX)).format('m:ss'),
    [getTargetTime, position.docX]
  );
  const popperElement = useRef(null);
  const { styles, attributes } = usePopper(virtualReference, popperElement.current);
  const isHovered = containerRef.current?.matches(':hover');
  return (
    <HoverContainer
      ref={containerRef}
      onClick={(event) => {
        const targetTime = getTargetTime(event.clientX);
        if (targetTime !== null) onSelectTime(targetTime);
      }}
    >
      <BarContainer>
        <ProgressFilledBar
          aria-valuemax={Math.floor(totalMillis / 1000)}
          aria-valuemin={0}
          aria-valuenow={Math.floor(currentMillis / 1000)}
          role="slider"
          style={{
            width: `${percentage * 100}%`,
            transition: disableWidthAnimation ? 'none' : 'width 0.25s linear',
          }}
        />
        <MouseTracker style={{ left: `${position.elX - 1}px` }} />
        {portalDiv &&
          isHovered &&
          ReactDOM.createPortal(
            <div ref={popperElement} style={styles.popper} {...attributes.popper}>
              <TimeTooltip>
                {targetTimeLabel}
                <Arrow />
              </TimeTooltip>
            </div>,
            portalDiv
          )}
      </BarContainer>
    </HoverContainer>
  );
};

const HoverContainer = styled.div`
  width: 100%;
  height: 10px;
  cursor: pointer;
  &:hover > div {
    height: 6px !important;
  }
`;

const BarContainer = styled.div`
  transition: all 0.25s ease;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 2px;
  &:hover {
    & div:last-child {
      display: block !important;
    }
  }
`;

const ProgressFilledBar = styled.div`
  background-color: ${theme.black};
  height: 100%;
  transition: width 0.25s linear;
`;

const MouseTracker = styled.div`
  display: none;
  position: absolute;
  background-color: ${theme.navy[100]};
  z-index: ${zIndex.popup};
  width: 3px;
  height: 100%;
`;

const TimeTooltip = styled.h6`
  background-color: white;
  border-radius: ${size(1)};
  border: 1px solid ${theme.navy[100]};
  padding: ${size(1.25)} ${size(1.5)};
  font-size: ${size(1.5)};
  filter: drop-shadow(1px 3px 9px rgba(11, 35, 48, 0.1));
  z-index: ${zIndex.popup};
  font-weight: 400;
  margin: 0;
`;

export const Arrow = styled.div`
  background: inherit;
  border-color: inherit;
  visibility: hidden;

  &:before {
    content: '';
    display: block;
    transform: rotate(45deg);
    border-width: 1px;
    border-right-style: solid;
    border-bottom-style: solid;
    position: absolute;
    width: 12px;
    height: 12px;
    z-index: -1;
    bottom: -7px;
    :before {
      transform: rotate(45deg);
    }
    background: inherit;
    border-color: inherit;
    visibility: visible;
    border-bottom-right-radius: 2px;
    left: calc(50% - 6px);
  }
`;
