import { ReplayEventType } from '@sprigShared/types';

import { type GroupedDigestEvent } from 'hooks';

export const getEventLabel = (type: ReplayEventType) => {
  if (type === ReplayEventType.Scroll) {
    return 'Scroll';
  }
  return 'Click';
};

const convertTagToReadable = (tag: string) => {
  switch (tag) {
    case 'A':
      return 'Link';
    case 'H1':
    case 'H2':
    case 'H3':
    case 'H4':
    case 'H5':
    case 'H6':
      return 'Header';
    case 'IMG':
      return 'Image';
    case 'P':
      return 'Text';
    default:
      return null;
  }
};

const convertToCapitalCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Return tooltip title for clicked element by checking the following in order:
 * 1. Accessibility label
 * 2. Role
 * 3. Tag name
 *   a. Convert tag name to more human readable name
 * @param { xpath, events, element }
 * @returns Label of the clicked element
 */
export const getTitle = ({ xpath, events, element }: GroupedDigestEvent) => {
  if (element?.ariaLabel) {
    return element.ariaLabel;
  }
  if (element?.role) {
    return convertToCapitalCase(element.role);
  }
  const splitXPath = xpath.split('/');
  const elementName = `${
    events[0]?.elementAttributes?.element ||
    splitXPath[splitXPath.length - 1]?.split('[')?.[0] ||
    element?.tagName ||
    ''
  }`;
  return convertTagToReadable(elementName.toUpperCase()) || convertToCapitalCase(elementName);
};

export const DEFAULT_SCROLLMAP_OPACITY = 70;
