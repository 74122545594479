import React from 'react';

import styled from '@emotion/styled';

import { Device } from 'components';

export const BackdropLoader = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const timestamp = urlParams.get('t');

  return (
    <Device isBackdrop timestamp={timestamp}>
      {(props) => {
        return <BackdropWrapper {...props}>invisibleText</BackdropWrapper>;
      }}
    </Device>
  );
};

// Hide text to satisfy React.DetailedHTMLProps
const BackdropWrapper = styled.div`
  opacity: 0;
`;
