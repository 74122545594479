import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ttCommonsPro, colors, typography } from 'twig';

export const globalStyles = css`
  ${ttCommonsPro}

  * {
    font-variant-ligatures: no-contextual;
  }

  body {
    font-family: 'TT Commons Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  input,
  button,
  textarea,
  .ql-snow {
    font-family: inherit;
  }
`;

const secondaryText = css`
  margin: 0;
  color: ${colors.typography.secondary};
`;

export const Title = styled.h2`
  ${typography.h4}
`;

export const MoreInfo = styled.p`
  ${secondaryText}
`;
