import React, { useState } from 'react';

import { useEvent } from 'react-use';

import { HeatmapType } from '@sprigShared/types-web';

import { Device } from 'components';

import { Clickmap } from '../clickmap';
import { Scrollmap } from '../scrollmap';

import { Heatmap } from './Heatmap';

export const HeatmapLoader = () => {
  const [type, setType] = useState<HeatmapType>(HeatmapType.Heatmap);
  const urlParams = new URLSearchParams(window.location.search);
  const timestamp = urlParams.get('t');

  useEvent('message', (e: MessageEvent) => {
    if (e.data?.type === 'heatmap-type') {
      setType(e.data?.heatmapType);
    }
  });

  return (
    <Device timestamp={timestamp}>
      {(props) => {
        switch (type) {
          case HeatmapType.Clickmap:
            return <Clickmap {...props} />;
          case HeatmapType.Scrollmap:
            return <Scrollmap {...props} />;
          case HeatmapType.Heatmap:
          default:
            return <Heatmap {...props} />;
        }
      }}
    </Device>
  );
};
