import { gunzip } from 'fflate';

import { ReplayData } from './types';

/**
 * fetchReplay fetches the desired replay data, then decompresses it if needed
 * For the promise, it will reject if any issues are found while performing
 * gunzip and if any issues are caught while decoding and parsing the data
 * @param replayUrl
 * @returns JSON of replay events (aka replayData) to be fed in to replayService playback
 */
export const fetchReplay = async (replayUrl: string): Promise<ReplayData> => {
  const dataResponse = await fetch(replayUrl, {
    method: 'GET',
  });
  if (!dataResponse.ok) {
    throw new Error(dataResponse.statusText);
  }
  try {
    // Try decompressing it
    const arrayBuffer = await dataResponse.clone().arrayBuffer();
    return await new Promise((res, rej) => {
      gunzip(new Uint8Array(arrayBuffer), (err, result) => {
        if (err) rej();
        try {
          res(JSON.parse(new TextDecoder().decode(result)));
        } catch {
          rej();
        }
      });
    });
  } catch {
    // If decompression fails, presumably the replay was not compressed
    return await dataResponse.json();
  }
};
