import React, { useCallback, useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import clamp from 'lodash/clamp';
import moment from 'moment';
import { useEvent } from 'react-use';
import { Replayer } from 'rrweb';

import analytics from '@sprigShared/analytics';
import { IconButton, theme, Toggle, Select, OptionType, Popup, Icon, size } from 'twig';

import { useLocalPlayerSettings, usePlayerToolbarState } from 'hooks/hooks';

import { TimerProgressBar } from './TimerProgressBar';

const PLAYBACK_SPEED_OPTIONS: OptionType<number>[] = [
  { label: '0.8x', value: 0.8 },
  { label: '1x', value: 1 },
  { label: '1.2x', value: 1.2 },
  { label: '1.5x', value: 1.5 },
  { label: '2x', value: 2 },
];

export const ReplayViewerToolbar = ({
  replayer,
  isFullScreen,
  toggleFullScreen,
}: {
  replayer: Replayer;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}) => {
  const { initialSpeed, initialSkipInactivity, saveSkipInactivity, saveSpeed } = useLocalPlayerSettings();
  const getPlayerState = useCallback(() => {
    const total = replayer.getMetaData()?.totalTime || 0;
    return {
      isPlaying: replayer.service?.state?.matches('playing'),
      timestamp: clamp(replayer.getCurrentTime(), 0, total),
      totalTime: total,
      skipInactive: replayer.config?.skipInactive,
      speed: replayer.config?.speed,
    };
  }, [replayer]);
  const { state: playerState, updateState: updatePlayerState } = usePlayerToolbarState(
    {
      isPlaying: false,
      timestamp: 0,
      totalTime: 1,
      skipInactive: initialSkipInactivity,
      speed: initialSpeed,
    },
    getPlayerState
  );
  useEffect(() => {
    replayer.setConfig({ skipInactive: initialSkipInactivity, speed: initialSpeed });
  }, [initialSkipInactivity, initialSpeed, replayer]);
  useEffect(() => {
    updatePlayerState();
    const { unsubscribe } = replayer.service.subscribe(() => {
      updatePlayerState();
    });
    const interval = setInterval(() => {
      updatePlayerState();
    }, 100);
    return () => {
      unsubscribe();
      clearInterval(interval);
    };
  }, [replayer, updatePlayerState]);

  const { isPlaying, timestamp, totalTime, skipInactive } = playerState;
  const isAtStart = timestamp <= 0;
  const isFinished = timestamp >= totalTime;

  const getTargetTime = (diffMillis: number) => {
    const current = replayer.getCurrentTime();
    const min = 0;
    const max = replayer.getMetaData().totalTime;
    const target = current + diffMillis;
    return clamp(target, min, max);
  };

  const setTime = (targetTime: number) => {
    if (isPlaying) {
      replayer.play(targetTime);
    } else {
      replayer.pause(targetTime);
    }
  };

  return (
    <VideoToolbar
      isPlaying={isPlaying}
      togglePlay={() => {
        if (isPlaying) {
          replayer.pause();
          analytics.track(analytics.evt.C_PAUSE_REPLAY);
        } else {
          isFinished ? replayer.play(0) : replayer.play(replayer.getCurrentTime());
          analytics.track(isFinished ? analytics.evt.C_RESTART_REPLAY : analytics.evt.C_RESUME_REPLAY);
        }
      }}
      skipBack={() => {
        setTime(getTargetTime(-10000));
        analytics.track(analytics.evt.C_REWIND_REPLAY);
      }}
      skipForward={() => {
        setTime(getTargetTime(10000));
        analytics.track(analytics.evt.C_FAST_FORWARD_REPLAY);
      }}
      currentMillis={timestamp}
      totalMillis={totalTime}
      skipBackEnabled={!isAtStart}
      skipForwardEnabled={!isFinished}
      skipInactive={skipInactive === true}
      toggleSkipInactive={() => {
        const updatedState = !replayer.config.skipInactive;
        saveSkipInactivity(updatedState);
        replayer.setConfig({ skipInactive: updatedState });
        analytics.track(analytics.evt.C_TOGGLE_SKIP_INACTIVITY, { isSkipInactivityOn: updatedState });
      }}
      setTime={setTime}
      setSpeed={(speed: number) => {
        saveSpeed(speed);
        replayer.setConfig({ speed: speed });
      }}
      speed={replayer.config.speed}
      isFullScreen={isFullScreen}
      toggleFullScreen={toggleFullScreen}
    />
  );
};

export const VideoToolbar = ({
  togglePlay,
  isPlaying,
  skipBack,
  skipForward,
  totalMillis,
  currentMillis,
  skipBackEnabled,
  skipForwardEnabled,
  skipInactive,
  toggleSkipInactive,
  setTime,
  setSpeed,
  speed,
  isFullScreen,
  toggleFullScreen,
}: {
  togglePlay: () => void;
  isPlaying: boolean;
  skipForward: () => void;
  skipBack: () => void;
  skipForwardEnabled: boolean;
  skipBackEnabled: boolean;
  totalMillis: number;
  currentMillis: number;
  skipInactive: boolean;
  toggleSkipInactive?: () => void;
  setTime: (millis: number) => void;
  setSpeed: (speed: number) => void;
  speed: number;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
}) => {
  const onSelectTime = (time: number) => {
    setTime(time);
    analytics.track(analytics.evt.C_SEEK_REPLAY);
  };
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      switch (event.key) {
        case 'ArrowRight':
          skipForward();
          break;
        case 'ArrowLeft':
          skipBack();
          break;
        case 'f':
          toggleFullScreen();
          break;
        case ' ':
          togglePlay();
      }
    },
    [skipBack, skipForward, togglePlay]
  );

  const playButtonRef = useRef<HTMLButtonElement>(null);

  useEvent('keydown', handleKeyDown);
  useEffect(() => {
    if (playButtonRef.current) {
      playButtonRef.current.focus();
    }
  }, []);
  return (
    <ToolbarContainer aria-label="Replay Toolbar">
      <TimerProgressBar currentMillis={currentMillis} totalMillis={totalMillis} onSelectTime={onSelectTime} />
      <ToolbarButtonsContainer>
        <PlayButtonGroup>
          <Popup
            trigger={
              <StyledButton
                aria-label="Back"
                disabled={!skipBackEnabled}
                icon="replay-rewind"
                onClick={skipBack}
                variant="secondary"
              />
            }
          >
            <PopupText>
              <div>Skip back 10 sesconds</div>
              <IconText>
                Press left arrow <PopupIcon src="arrow-left" size={2} />
              </IconText>
            </PopupText>
          </Popup>
          <StyledButton
            ref={playButtonRef}
            aria-label={isPlaying ? 'Pause' : 'Play'}
            icon={isPlaying ? 'pause' : 'play'}
            onClick={togglePlay}
            variant="primary"
          />
          <Popup
            trigger={
              <StyledButton
                aria-label="Forward"
                disabled={!skipForwardEnabled}
                icon="replay-fast-forward"
                onClick={skipForward}
                variant="secondary"
              />
            }
          >
            <PopupText>
              <div>Skip ahead 10 seconds</div>
              <IconText>
                Press right arrow <PopupIcon src="arrow-right" size={2} />
              </IconText>
            </PopupText>
          </Popup>
        </PlayButtonGroup>
        <TimeDisplay currentMillis={currentMillis} totalMillis={totalMillis} />
        <RightSideContainer>
          {toggleSkipInactive && (
            <SkipInactivityToggle
              labelPlacement="start"
              label="Skip inactivity"
              aria-label="Skip Inactivity Toggle"
              checked={skipInactive}
              onChange={toggleSkipInactive}
            />
          )}
          <Select disableSearch onChange={setSpeed} options={PLAYBACK_SPEED_OPTIONS} value={speed} menuAtRoot={false} />
          <Popup
            trigger={
              <IconButton
                aria-label={`${isFullScreen ? 'Minimize' : 'Expand to'} Fullscreen`}
                icon={`arrows-${isFullScreen ? 'in' : 'out'}-simple`}
                onClick={toggleFullScreen}
                variant="secondary"
              />
            }
          >
            <PopupText>
              <IconText>
                Fullscreen <FIcon>F</FIcon>
              </IconText>
            </PopupText>
          </Popup>
        </RightSideContainer>
      </ToolbarButtonsContainer>
    </ToolbarContainer>
  );
};

const TimeDisplay = ({ currentMillis, totalMillis }: { currentMillis: number; totalMillis: number }) => {
  const currentTimeLabel = moment.utc(currentMillis).format('m:ss');
  const totalTimeLabel = moment.utc(totalMillis).format('m:ss');
  return (
    <TimeContainer>
      <TimeLabel aria-label="Current Time">{currentTimeLabel}</TimeLabel>
      <TimeDivider>&nbsp;/&nbsp;</TimeDivider>
      <TimeLabel aria-label="Total Time">{totalTimeLabel}</TimeLabel>
    </TimeContainer>
  );
};

const PlayButtonGroup = styled.div`
  display: flex;
  gap: 0;
  margin: -4px;
`;

// The margin is due to a react bug affecting the tooltip: https://github.com/facebook/react/issues/10109#issuecomment-314400981
const StyledButton = styled(IconButton)`
  margin: 4px;
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
`;

const TimeLabel = styled.h6`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  flex: 0 0 35px;
  text-align: center;
  margin: 0;
  color: ${theme.primary};
`;

const TimeDivider = styled.h6`
  font-size: 14px;
  font-weight: 600;
`;

const ToolbarContainer = styled.div`
  width: 100%;
  height: 72px;
  border-radius: 8px;
  background-color: ${theme.white};
  border: 1px solid ${theme.gray3};
  box-shadow: 0px 2px 6px rgba(11, 35, 48, 0.05);
  display: flex;
  flex-direction: column;
  overflow: auto hidden;
`;

const ToolbarButtonsContainer = styled.div`
  display: flex;
  flex: 1 1 1px;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 16px 16px;
  gap: 8px;
  box-sizing: border-box;
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 1px;
  height: fit-content;
  gap: 8px;
`;

const SkipInactivityToggle = styled(Toggle)`
  font-size: 14px;
  margin-right: 2px;
`;

const PopupText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.primary};
`;

const PopupIcon = styled(Icon)`
  border-radius: 4px;
  border: 1px solid ${theme.gray25};
  margin-left: 6px;
`;

const IconText = styled.div`
  display: flex;
`;

const FIcon = styled.div`
  border: 1px solid ${theme.gray25};
  border-radius: ${size(0.5)};
  color: ${theme.secondary};
  font-size: ${size(1.5)};
  height: ${size(2.25)};
  margin-left: ${size(0.75)};
  text-align: center;
  width: ${size(2.25)};
`;
