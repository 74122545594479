import React, { useRef, useEffect, useState, useMemo } from 'react';

import styled from '@emotion/styled';
import slice from 'lodash/slice';

import { environment } from '@sprigShared/environment';
import { TOP_LEVEL_HTML_STRING, DigestEvent } from '@sprigShared/types';
import { colors, size, Popup } from 'twig';

import { DeviceChildrenProps, TooltipContent } from 'components';
import { Title, MoreInfo } from 'styles';

import { PercentageObject, useCalculateGradientCss } from './hooks';

export const Scrollmap = ({ foundElements: { eventsByXpath }, hasSessionReplay, opacity }: DeviceChildrenProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [percentageObj, setPercentageObj] = useState<PercentageObject | null>(null);
  const [yPosition, setYPosition] = useState<number | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const sortedEvents: DigestEvent[] = useMemo(() => {
    return eventsByXpath[TOP_LEVEL_HTML_STRING]?.events.sort(
      (a, b) => (a.scrollPercentage || 0) - (b.scrollPercentage || 0)
    );
  }, [eventsByXpath]);

  // key -> the percentage of users that scroll down
  // value -> object with the index and what the percentage of scrolling down
  const percentageMap = useMemo(() => {
    if (!sortedEvents?.length) return {};

    const percentageUserScrollDown: Record<string, PercentageObject> = {
      100: {
        index: 0,
        totalUserCount: sortedEvents.length,
        percentageScrollDown: sortedEvents[0].scrollPercentage || 0,
        percentageOfUsers: 100,
      },
    };

    // iterate through events until done mapping
    for (let i = 1; i < sortedEvents.length; i++) {
      const percentage = Math.round(((sortedEvents.length - i) / sortedEvents.length) * 100);
      const percentScrolledDown = sortedEvents[i].scrollPercentage;

      // if we hit an event without a scroll percentage, continue to check if the next event has one
      if (!percentScrolledDown) continue;
      // if we hit a percentage that is greater than 100, break
      else if (percentScrolledDown > 100) break;

      if (percentageUserScrollDown[percentage]) {
        percentageUserScrollDown[percentage].percentageScrollDown = percentScrolledDown;
        continue; // skip to next number if we already did the calc for it
      }

      // handle percentage of users that doesn't exist in the map
      percentageUserScrollDown[percentage] = {
        index: i,
        totalUserCount: sortedEvents.length - i,
        percentageScrollDown: percentScrolledDown,
        percentageOfUsers: percentage,
      };

      if (percentScrolledDown === 100) break; // if at 100, we can break out of the loop
    }

    return percentageUserScrollDown;
  }, [sortedEvents]);

  // Configure the linear gradient for the scrollmap container
  const gradientCss = useCalculateGradientCss(percentageMap, opacity);

  const uniqueSessionReplayIds = useMemo(() => {
    if (!sortedEvents?.length || !percentageObj || percentageObj.index === -1) return [];

    const getEvents = slice(sortedEvents, percentageObj?.index);
    return Array.from(new Set(getEvents.map(({ sessionReplayId }) => sessionReplayId)));
  }, [sortedEvents, percentageObj]);

  useEffect(() => {
    const setFromEvent = (event: MouseEvent) => {
      if (!ref.current || tooltipOpen) return;

      const { top, height } = ref.current.getBoundingClientRect();
      const currYPosition = event.y - top;

      if (currYPosition >= 0 && currYPosition < height) {
        const mouseScrollDepthPercentage = Math.round((currYPosition / height) * 100);

        const [, foundPercentage] =
          Object.entries(percentageMap)
            .filter(([, value]) => mouseScrollDepthPercentage <= value.percentageScrollDown)
            .pop() || [];

        setYPosition(currYPosition);
        setPercentageObj(
          foundPercentage ?? { index: -1, totalUserCount: 0, percentageScrollDown: 0, percentageOfUsers: 0 }
        );
      } else {
        setYPosition(null);
      }
    };
    if (ref?.current) {
      ref.current?.addEventListener('mousemove', setFromEvent);
      return () => {
        ref.current?.removeEventListener('mousemove', setFromEvent);
      };
    }
  }, [ref, percentageMap, tooltipOpen]);

  const handleMouseLeave = () => {
    setYPosition(null);
    setTooltipOpen(false);
  };

  const stats = useMemo(
    () => ({
      totalSessions: sortedEvents?.length,
      totalClicks: sortedEvents?.length,
    }),
    [sortedEvents]
  );

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-stats-updated',
        stats: stats,
      },
      environment.appUrl
    );
  }, [stats]);

  return (
    <ScrollmapContainer ref={ref} onMouseLeave={handleMouseLeave} gradientCss={gradientCss}>
      {yPosition && percentageObj ? (
        <LineContainer y={yPosition}>
          <Popup
            on="click"
            onClose={() => setTooltipOpen(false)}
            arrow={false}
            open={tooltipOpen}
            placement="bottom"
            trigger={
              <div onClick={() => setTooltipOpen(!tooltipOpen)}>
                <Line />
                <Pill>{`${percentageObj.percentageOfUsers}% reached this point`}</Pill>
              </div>
            }
            contentContainerStyle={{ padding: '0px', marginTop: '-20px' }}
            wrapperStyle={{ margin: '0 auto' }}
          >
            {percentageObj.totalUserCount > 0 && (
              <TooltipContent showReplayButton={hasSessionReplay} uniqueSessionReplayIds={uniqueSessionReplayIds}>
                <Title>{`${percentageObj.percentageOfUsers}% reached this point`}</Title>
                <MoreInfo>{`${percentageObj.totalUserCount} out of ${sortedEvents.length} captures`}</MoreInfo>
              </TooltipContent>
            )}
          </Popup>
        </LineContainer>
      ) : null}
    </ScrollmapContainer>
  );
};

const ScrollmapContainer = styled.div<{ gradientCss?: string }>`
  height: 100%;
  width: 100%;
  ${({ gradientCss }) => (gradientCss ? `background: linear-gradient(to bottom, ${gradientCss});` : '')}
`;

const LineContainer = styled.div<{ y: number | null }>`
  width: 100%;
  position: relative;
  top: ${({ y }) => `${y || 0}px`};
  display: grid;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  border-top: 1px dashed ${colors.border.dark};
`;

const Pill = styled.div`
  background-color: ${colors.background.dark};
  color: ${colors.common.white};
  margin: 0 auto;
  width: fit-content;
  border-radius: 25px;
  padding: ${size(0.5)} ${size(1.5)};
  position: relative;
  transform: translateY(-50%);

  :hover {
    cursor: pointer;
  }
`;
