import React, { useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import h337, { Heatmap as HeatmapJs } from 'heatmap.js';

import { colors } from 'twig';

import { type DeviceChildrenProps } from 'components';

const HeatmapContainer = styled.div<{ opacity: number }>`
  height: 100%;
  width: 100%;
  filter: opacity(${(p) => p.opacity});
`;

export const Heatmap = ({ foundElements: { foundEvents }, opacity }: DeviceChildrenProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [heatmapInstance, setHeatmapInstance] = useState<HeatmapJs<'value', 'x', 'y'>>();
  useEffect(() => {
    if (heatmapInstance && ref?.current) {
      ref?.current?.replaceChildren();
    }
    if (ref?.current) {
      const data: { x: number; y: number; value: number }[] = [];
      const lengths: number[] = [];

      foundEvents.forEach(({ events }) => {
        lengths.push(events.length);
        events?.forEach(({ adjustedY, adjustedX }) => {
          if (adjustedY && adjustedX) {
            data.push({
              x: adjustedX,
              y: adjustedY,
              value: events.length,
            });
          }
        });
      });

      const he = h337.create({
        container: ref.current,
        radius: 20,
        // maxOpacity: 0.8,
        // minOpacity: 0.05,
        blur: 0.85,
        gradient: {
          '.0': colors.intensity[0],
          '.17': colors.intensity[17],
          '.4': colors.intensity[40],
          '.7': colors.intensity[70],
          '.81': colors.intensity[81],
          '1': colors.intensity[100],
        },
      });

      he.setData({
        data,
        min: 0,
        max: Math.max(...lengths),
      });

      setHeatmapInstance(he);
    }
  }, [foundEvents, ref]);

  return <HeatmapContainer opacity={opacity} ref={ref} />;
};
