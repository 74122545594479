import React from 'react';

import styled from '@emotion/styled';

import { useReplayUrl } from 'hooks/hooks';

import { ReplayVideoViewer } from './ReplayerVideoViewer';
import { ReplayViewer } from './ReplayViewer';

export const MUX_HOST_URL = 'https://stream.mux.com';

export const ReplayLoader = () => {
  const { replay: replayUrl, isMobile } = useReplayUrl();

  return (
    <Container data-testid="Replay Viewer">
      {isMobile ? <ReplayVideoViewer videoUrl={replayUrl} /> : <ReplayViewer />}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & * {
    box-sizing: border-box;
  }
`;
