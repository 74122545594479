import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';

import styled from '@emotion/styled';
import pluralize from 'pluralize';

import { environment } from '@sprigShared/environment';
import { Popup } from 'twig';

import { type GroupedDigestEvent, SPRIG_HIGHLIGHT_CLASS_NAME } from 'hooks';
import { Title, MoreInfo } from 'styles';
import { getEventLabel, getTitle } from 'utils/helpers';

import { TooltipContent } from './TooltipContent';

const TOOLTIP_OFFSET_X = 30;
const TOOLTIP_OFFSET_Y = 75;

const TooltipAnchor = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
`;

const TooltipWrapper = styled.div<{ bottom: number; right: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: ${({ right }) => `${right}px`};
  bottom: ${({ bottom }) => `${bottom}px`};
  height: 100%;
  width: 100%;
`;

export const Tooltip = ({
  getElementAtPoint,
  bottom,
  right,
  hasSessionReplay,
  foundEvents,
  onFocus,
}: {
  bottom: number;
  right: number;
  getElementAtPoint: (x: number, y: number) => undefined | GroupedDigestEvent;
  hasSessionReplay?: boolean;
  foundEvents: GroupedDigestEvent[];
  onFocus: (e: HTMLElement | undefined) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [tooltipInfo, setTooltipInfo] = useState<undefined | GroupedDigestEvent>();
  const [{ x, y }, setTooltipLocation] = useState({ x: 0, y: 0 });
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const hoverFunction = useCallback(
    (event: MouseEvent) => {
      if (!ref.current || isTooltipOpen) {
        return;
      }
      const { top, left } = ref.current.getBoundingClientRect();
      const elementData = getElementAtPoint(event.x - left, event.y - top);

      setTooltipInfo((old) => {
        if (old?.element) {
          old.element.classList.remove(SPRIG_HIGHLIGHT_CLASS_NAME);
        }

        if (elementData?.element) {
          elementData.element.classList.add(SPRIG_HIGHLIGHT_CLASS_NAME);
          return elementData;
        }

        return undefined;
      });
    },
    [setTooltipInfo, getElementAtPoint, isTooltipOpen]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current?.addEventListener('mousemove', hoverFunction);

      return () => {
        tooltipInfo?.element?.classList.remove(SPRIG_HIGHLIGHT_CLASS_NAME);
        ref.current?.removeEventListener('mousemove', hoverFunction);
      };
    }
  }, [ref, hoverFunction]);

  const clickFunction = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-iframe-clicked',
      },
      environment.appUrl
    );
  }, []);

  useEffect(() => {
    if (ref.current) {
      const currentRef = ref.current;
      currentRef.addEventListener('mousedown', clickFunction);

      return () => {
        currentRef.removeEventListener('mousedown', clickFunction);
      };
    }
  }, [ref, clickFunction]);

  const handleTooltipClick = (e: React.MouseEvent<HTMLElement>) => {
    const newTooltipState = !isTooltipOpen;
    if (ref.current) {
      const bounds = ref.current.getBoundingClientRect();
      setTooltipLocation({
        x: e.clientX - bounds.left + TOOLTIP_OFFSET_X,
        y: e.clientY - bounds.top + TOOLTIP_OFFSET_Y,
      });
    }
    setIsTooltipOpen(newTooltipState);
    onFocus(newTooltipState ? tooltipInfo?.element : undefined);
  };

  const stats = useMemo(() => {
    return {
      totalSessions: new Set(foundEvents.flatMap(({ events }) => events.map(({ sessionReplayId }) => sessionReplayId)))
        .size,
      totalClicks: foundEvents.flatMap(({ events }) => events).length,
    };
  }, [foundEvents]);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'heatmap-stats-updated',
        stats: stats,
      },
      environment.appUrl
    );
  }, [stats]);

  const renderTooltipContent = ({ events, xpath, element }: GroupedDigestEvent) => {
    const title = getTitle({ events, xpath, element });
    const eventText = `${getEventLabel(events[0]?.type).toLowerCase()}`;
    const eventsLength = events.length;
    const clickPercent = stats.totalClicks ? ` (${((eventsLength / stats.totalClicks) * 100).toFixed(1)}%)` : '';
    const uniqueSessionReplayIds = Array.from(new Set(events.map(({ sessionReplayId }) => sessionReplayId)));
    const sessionReplaysLength = uniqueSessionReplayIds.length;
    const sessionPercent = stats.totalSessions
      ? `(${((sessionReplaysLength / stats.totalSessions) * 100).toFixed(1)}%)`
      : '';
    return (
      <TooltipContent showReplayButton={hasSessionReplay} uniqueSessionReplayIds={uniqueSessionReplayIds}>
        <Title>{title}</Title>
        <MoreInfo>{`${sessionReplaysLength} of ${stats.totalSessions} ${pluralize(
          'capture',
          stats.totalSessions
        )} ${sessionPercent}`}</MoreInfo>
        <MoreInfo>{`${eventsLength} of ${stats.totalClicks} ${pluralize(
          eventText,
          stats.totalClicks
        )} ${clickPercent}`}</MoreInfo>
      </TooltipContent>
    );
  };

  return (
    <TooltipWrapper onClick={handleTooltipClick} bottom={bottom} right={right} ref={ref}>
      <TooltipAnchor x={x} y={y - (ref.current?.getBoundingClientRect?.().top ?? 0)}>
        {isTooltipOpen && tooltipInfo ? (
          <Popup
            on="click"
            arrow={false}
            open={isTooltipOpen}
            placement="right"
            trigger={null}
            contentContainerStyle={{ padding: '0px' }}
          >
            {renderTooltipContent(tooltipInfo)}
          </Popup>
        ) : null}
      </TooltipAnchor>
    </TooltipWrapper>
  );
};
