import React from 'react';

import styled from '@emotion/styled';
import pluralize from 'pluralize';

import { environment } from '@sprigShared/environment';
import { Button, colors, size } from 'twig';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildWrapper = styled.div`
  padding: ${size(2)};
  display: flex;
  flex-direction: column;
  gap: ${size(1)};
`;

const ViewClipsButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${size(1)} ${size(2)};
  border-radius: 0 0 ${size(1.25)} ${size(1.25)};
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.border.light};
`;

export const TooltipContent = ({
  showReplayButton,
  uniqueSessionReplayIds = [],
  children,
}: {
  children: React.ReactNode;
  showReplayButton?: boolean;
  uniqueSessionReplayIds?: string[];
}) => {
  const handleViewClips = () => {
    window.parent.postMessage(
      {
        type: 'heatmap-view-clips',
        replayIds: uniqueSessionReplayIds,
      },
      environment.appUrl
    );
  };

  return (
    <Wrapper>
      <ChildWrapper>{children}</ChildWrapper>
      {showReplayButton ? (
        <>
          <Divider />
          <ViewClipsButton onClick={handleViewClips} variant="tertiary" startIcon="play">
            View {pluralize('Replay', uniqueSessionReplayIds.length)}
          </ViewClipsButton>
        </>
      ) : null}
    </Wrapper>
  );
};
