import { useMemo } from 'react';

import Color from 'color';
import isEmpty from 'lodash/isEmpty';

import { colors } from 'twig';

import { DEFAULT_SCROLLMAP_OPACITY } from 'utils';

export interface PercentageObject {
  index: number;
  totalUserCount: number;
  percentageScrollDown: number;
  percentageOfUsers: number;
}

export const useCalculateGradientCss = (percentageMap: Record<string, PercentageObject>, opacity: number) =>
  useMemo(() => calculateGradientCss(percentageMap, opacity), [percentageMap, opacity]);

export const calculateGradientCss = (percentageMap: Record<string, PercentageObject>, opacity: number) => {
  if (isEmpty(percentageMap)) return '';

  // Step 1: We calculate the percentage of users that scroll down for each intensity based on colors.intensity.
  // In this step, intesity color represents the % of users who scrolled down
  // gradientPercentagePoints is a map of intensity # (aka % of users who scrolled down) -> % of page scrolled down
  const gradientPercentagePoints: Record<number, number> = {};
  let lastPercentageScrolledDown = 0;
  Object.keys(colors.intensity)
    .reverse() // We reverse the keys so we can start from the highest intensity
    .forEach((intensityKey) => {
      const intensityKeyNum = Number(intensityKey);
      const findPercentage = Object.entries(percentageMap).find(
        ([, value]) => intensityKeyNum <= value.percentageOfUsers
      );

      // We want to ignore any intesity that repeats the same percentage of users who scrolled down
      if (!findPercentage || findPercentage[1].percentageScrollDown === lastPercentageScrolledDown) return;

      gradientPercentagePoints[intensityKeyNum] = findPercentage[1].percentageScrollDown;
      lastPercentageScrolledDown = findPercentage[1].percentageScrollDown;
    });

  // Step 2: We add margins to the gradientPercentagePoints to make sure the actual gradient is only happening for a short distance
  // Without this step, the actual gradient part would vary depending on the distance of each intensity color
  // The margins will be +/-1%
  const margin = 1;
  const adjustedOpacity = (opacity * DEFAULT_SCROLLMAP_OPACITY) / 100;
  const intensityKeys: string[] = [];
  lastPercentageScrolledDown = 0;
  Object.entries(gradientPercentagePoints)
    .reverse() // We reverse the keys so we can start from the highest intensity
    .forEach(([intensityKey, percentageScrolledDown]) => {
      const intensityKeyNum = Number(intensityKey);
      const color: string = colors.intensity[intensityKeyNum];

      const lowerEnd = lastPercentageScrolledDown;
      const higherEnd = percentageScrolledDown !== 100 ? percentageScrolledDown - margin : 100;
      // We push twice to indicate the start and end of where the pure colors are
      // the hex opacity value at the end of hex represents the opacity
      const hexWithOpacity = Color(color).alpha(adjustedOpacity).hexa();
      intensityKeys.push(`${hexWithOpacity} ${lowerEnd}%`);
      intensityKeys.push(`${hexWithOpacity} ${higherEnd}%`);

      // We update the last percentage scrolled down to next color's start
      lastPercentageScrolledDown = Math.min(percentageScrolledDown + margin, 100);
    });

  // If there are no intensity keys, we return an empty string
  if (!intensityKeys.length) return '';

  // Step 3: If it doesn't reach the end, we need to manually add in the last color at the end.
  const lastIntesityKey = intensityKeys[intensityKeys.length - 1].split(' ')[1];
  if (lastIntesityKey !== '100%') {
    const lastHexWithOpacity = Color(colors.intensity[0]).alpha(adjustedOpacity).hexa();
    intensityKeys.push(`${lastHexWithOpacity} ${lastPercentageScrolledDown}%`);
    intensityKeys.push(`${lastHexWithOpacity} 100%`);
  }

  return intensityKeys.join(', ');
};
