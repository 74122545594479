interface Component {
  name?: string;
  position: number | null;
}

// get the position of the given node among its previous sibling nodes that share the same tag name
const getPosition = (node: Element | Attr) => {
  if (node instanceof Attr) {
    return null;
  }

  let position = 1;
  for (let curNode = node.previousSibling; curNode; curNode = curNode.previousSibling) {
    if (curNode.nodeName === node.nodeName) {
      ++position;
    }
  }
  return position;
};

export const getXPath = (node: Element | Attr | null): string => {
  if (node === null) {
    return '';
  }

  const comps: Component[] = [];

  if (node instanceof Document) {
    return '/';
  }

  for (
    let curNode: Element | Attr | null = node;
    curNode && !(curNode instanceof Document);
    curNode = curNode instanceof Attr ? curNode.ownerElement : curNode.parentElement
  ) {
    if (curNode === null) {
      break;
    }

    const comp: Component = (comps[comps.length] = {
      name: undefined,
      position: null,
    });

    switch (curNode.nodeType) {
      case Node.TEXT_NODE:
        comp.name = 'text()';
        break;
      case Node.ATTRIBUTE_NODE:
        comp.name = '@' + curNode.nodeName;
        break;
      case Node.PROCESSING_INSTRUCTION_NODE:
        comp.name = 'processing-instruction()';
        break;
      case Node.COMMENT_NODE:
        comp.name = 'comment()';
        break;
      case Node.ELEMENT_NODE:
        comp.name = curNode.nodeName;
        break;
    }

    comp.position = getPosition(curNode);
  }

  return (
    '/' +
    comps
      .reverse()
      .map((comp) => (comp.position !== null ? `/${comp.name}[${comp.position}]` : `/${comp.name}`))
      .join('')
  );
};
