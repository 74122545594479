import React from 'react';

import styled from '@emotion/styled';

import { Icon, theme } from 'twig';

export const ReplayerError = ({ showErrorMessage }: { showErrorMessage: boolean }) => {
  if (!showErrorMessage) return null;

  return (
    <ErrorContainer>
      <ErrorIcon src="warning" variant="fill" fill={theme.merigold} size={4} />
      <ErrorMessage>Error loading replay</ErrorMessage>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
  color: ${theme.merigold};
`;

const ErrorIcon = styled(Icon)`
  margin-bottom: 10px;
  color: ${theme.merigold};
`;

const ErrorMessage = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: ${theme.merigoldText};
`;
