import styled from '@emotion/styled';

import { theme } from 'twig';

export const PlayerContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${theme.gray30};
`;

export const VideoContainer = styled.div<{ align?: boolean }>`
  margin: 16px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(p) => (p.align ? 'align-items: center;' : '')}
  flex: 1 1 0px;
  min-height: 0px;
  position: relative;
  & iframe {
    border: none;
    border-radius: 12px;
    transform-origin: center;
  }
  & .replayer-wrapper {
    filter: drop-shadow(1px 3px 9px rgba(11, 35, 48, 0.05));
  }
`;

export const MobileVideoContainer = styled.div`
  margin: 16px auto;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0px;
  min-height: 0px;
  position: relative;
  width: fit-content;
`;

export const ToolbarContainer = styled.div`
  flex: 0 0 1px;
  height: fit-content;
  width: calc(100% - 32px);
  margin: 0px 16px 16px;
`;
